<template>
  <div>
    <b-card
      class="card-profile objetfit card-leads-general"
      :img-src="campaign.cover"
      img-height="250px"
      img-top
    >
      <div class="profile-image-wrapper profile-image-wrapper-left">
        <div class="profile-image profile-image-100 p-0">
          <b-img thumbnail :src="campaign.logo"></b-img>
        </div>
      </div>
      <div class="profile-image-wrapper profile-image-wrapper-left">
        <div class="profile-image profile-text p-0">
          <h3 class="title mb-1" v-if="slug === 'grandmaster'">{{ $t('generalLeads.title') }}: {{ campaign.title }}</h3>
          <h3 class="title mb-1" v-else>{{ campaign.title }}</h3>
          <p class="text-left white">
            <span v-if="Object.keys(user).length > 0">{{ $t("generalLeads.user") }}</span> {{ user.first_name }}
            {{ user.last_name }}
          </p>
          <div class="profile-header-nav text-center">
            <b-navbar toggleable="md" class="navbar-lead" type="light" v-if="Object.keys(user).length > 0">
              <!-- toggle button -->
              <b-navbar-toggle class="float-right" target="nav-text-collapse">
                <feather-icon icon="AlignJustifyIcon" size="21"/>
              </b-navbar-toggle>
              <b-collapse id="nav-text-collapse" is-nav>
                <b-tabs
                  class="profile-tabs mt-1 mt-md-0"
                  nav-class="mb-0"
                  align="center"
                  pills
                >
                  <b-tab
                    :title="$t('generalLeads.campaign')"
                    v-on:click="changeTab('brief')"
                    :active="!participation_approved"
                  >
                  </b-tab>
                  <b-tab
                    v-if="participation_approved"
                    :title="$t('generalLeads.general')"
                    v-on:click="changeTab('general')"
                    :active="participation_approved"
                  >
                  </b-tab>
                  <b-tab
                    v-if="participation_approved"
                    :title="$t('generalLeads.detail')"
                    v-on:click="changeTab('details')"
                  >
                  </b-tab>
                  <b-tab
                    v-if="participation_approved"
                    :title="$t('generalLeads.payments')"
                    v-on:click="changeTab('payments')"
                  ></b-tab>
                </b-tabs>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
      </div>
    </b-card>
    <div v-if="tab_active === 'brief'">
      <b-row class="match-height">
        <b-col
          md="9"
        >
          <b-card>
            <h2>{{ $t('grandMaster.campaignDetail') }}</h2>
            <hr>
            <b-row class="text-justify">
              <b-col md="5">
                <h3 class="mb-1"><b-icon icon="award" style="color: #6441a5"/> {{$t("grandMaster.userBenefit")}}</h3>
                <ol v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>{{$t("grandMaster.text1")}}
                  </li>
                  <li>{{$t("grandMaster.text2")}}</li>
                  <li>{{$t("grandMaster.text3")}}
                  </li>
                </ol>

                <ol v-else style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>Te pagamos $ 10 USD por añadir la canción <a href="https://www.tiktok.com/music/Mami-Chula-7224277640403912706" target="_blank">"Mami Chula de Jhayco & Quevedo" en un Tik Tok.</a>
                  </li>
                  <li>El pago será vía PayPal o Wize el 15 o 30 de mayo ( Si publicas el contenido antes del 15 el pago es el 15, si es entre el 15 y 30 el pago será el 30 de Mayo)</li>
                  <li>Puedes <a href="https://www.tiktok.com/music/Mami-Chula-7224277640403912706" target="_blank">ver el link directo a la canción dando clic aquí</a>
                  </li>
                </ol>

                <h3 class="mb-1"><b-icon icon="briefcase" style="color: #6441a5"/> {{$t("grandMaster.text4")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>{{$t("grandMaster.text5")}}</li>
                  <li>{{$t("grandMaster.text6")}}</li>
                </ul>
                <ul v-else style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>Esta campaña únicamente tiene beneficio monetario para ti sin tener beneficio a tus seguidores</li>
                </ul>
                <h3 class="mb-1"><b-icon icon="bullseye" style="color: #6441a5"/> {{$t("grandMaster.text7")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;">
                  <li>{{$t("grandMaster.text8")}}</li>
                  <li>{{$t("grandMaster.text9")}}
                  </li>
                </ul>

                <ul v-else style="margin-left: 30px;list-style-type: square;">
                  <li>Dar a conocer la nueva canción <a href="https://www.tiktok.com/music/Mami-Chula-7224277640403912706" target="_blank">"Mami Chula de Jhayco & Quevedo"</a></li>
                </ul>
              </b-col>

              <b-col md="5" offset-md="1">
                <h3 class="mb-1"><b-icon icon="shield-check" style="color: #6441a5"/> {{$t("grandMaster.text10")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>{{$t("grandMaster.text11")}}</li>
                  <li>{{$t("grandMaster.text12")}}</li>
                  <li>{{$t("grandMaster.text13")}}</li>
                </ul>
                <ul v-else style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>Grabar un TikTok haciendo la coreografía propuesta en el video añadiendo la canción <a href="https://www.tiktok.com/music/Mami-Chula-7224277640403912706" target="_blank">"Mami Chula de Jhayco & Quevedo"</a></li>
                </ul>
                <h3 class="mb-1"><b-icon icon="shield-slash" style="color: #6441a5"/> {{$t("grandMaster.text14")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>{{$t("grandMaster.text15")}}</li>
                  <li>{{$t("grandMaster.text16")}}</li>
                </ul>
                <ul v-else style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>En esta campaña es necesario hacer TikTok como los ejemplos mostrados por lo que otro tipo de contenido no podría aceptarse</li>
                </ul>
                <h3 class="mb-1"><b-icon icon="hash" style="color: #6441a5"/>{{$t("grandMaster.text17")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>{{$t("grandMaster.text23")}}</li>
                  <li>Instagram - <b-link href="https://www.instagram.com/grandmastersacademy/" target="_blank">@grandmastersacademy</b-link></li>
                  <li>TikTok - <b-link href="https://www.tiktok.com/@grandmastersacademy" target="_blank">@grandmastersacademy</b-link></li>
                  <li>Twitter - <b-link href="https://twitter.com/grandmastersaca" target="_blank">@GrandMastersAca</b-link></li>
                  <li>Facebook - <b-link href="https://www.facebook.com/grandmastersacad" target="_blank">@grandmastersacad</b-link></li>
                  <li>LinkedIn - <b-link href="https://www.linkedin.com/company/grandmastersacademy/" target="_blank">@grandmasters</b-link></li>
                </ul>
                <ul v-else style="margin-left: 30px;list-style-type: square;" class="mb-4">
                  <li>#VidaRockstarSZN</li>
                  <li>TikTok - <b-link href="https://www.tiktok.com/@jhayco" target="_blank">@jhayco</b-link></li>
                </ul>
                <h3 class="mb-1"><b-icon icon="heart" style="color: #6441a5"/> {{$t("grandMaster.text18")}}</h3>
                <ul v-if="slug === 'grandmaster'" style="margin-left: 30px;list-style-type: square;">
                  <li>{{$t("grandMaster.text19")}}</li>
                  <li>{{$t("grandMaster.text20")}}</li>
                  <li>{{$t("grandMaster.text21")}}
                  </li>
                  <li>{{$t("grandMaster.text22")}}</li>
                </ul>
                <ul v-else style="margin-left: 30px;list-style-type: square;">
                  <li>El mejor consejo es adecuarde a los ejemplos de video 😊</li>
                </ul>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          md="3">
          <b-card>
            <h2>{{$t("grandMaster.exampleContent")}}</h2>
            <hr>
            <video
              style="width: 100%;"
              width="450" controls
              src="@/assets/images/leads/brief/grandmasters.mp4"
              v-if="slug === 'grandmaster'"
            ></video>
            <!-- <swiper
              v-if="slug !== 'grandmaster'"
              class="swiper-navigations"
              :options="swiper_options"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            >
              <swiper-slide>
                <video src="https://brandme.la/wp-content/mami-1.mp4" class="video-swiper" style="width: 100%;" width="450" controls></video>
              </swiper-slide>
              <swiper-slide>
                <video src="https://brandme.la/wp-content/mami-2.mp4" class="video-swiper" style="width: 100%;" width="450" controls></video>
              </swiper-slide>

              <div slot="button-next" class="swiper-button-next"/>
              <div slot="button-prev" class="swiper-button-prev"/>
            </swiper> -->
          </b-card>
        </b-col>
      </b-row>

      <b-embed
        type="iframe"
        aspect="16by9"
        :src="link_form"
        allowfullscreen
        v-if="participation.participation === null"
      ></b-embed>

      <b-row class="mt-3">
        <b-col
          cols="12"
          class="col-lg-3 col-md-6 col-sm-12"
          v-for="item in cardsSocialNet"
          v-bind:key="item.id"
        >
          <b-card class="px-n1 cardNet">
            <b-card-header class="p-0 pb-1">
              <div>
                <b-avatar
                  size="48"
                  class="mr-1 avatar bg-white"
                  :src="item.avat1"
                ></b-avatar>
                <b-avatar size="48" class="avatar" :src="item.avat2"></b-avatar>

                <div class="float-right ml-2">
                  <b-card-title class="mb-1"> {{ item.title }}</b-card-title>
                  <b-card-sub-title> {{ item.subtitle }}</b-card-sub-title>
                </div>
              </div>
            </b-card-header>

            <b-card-body class="p-0">
              <b-card-text class="text-justify" v-if="item.content">
                {{ item.content }}
              </b-card-text>
              <b-img :src="item.img" fluid-grow></b-img>
            </b-card-body>
            <b-card-footer class="border-top-0">
              <b-row class="d-flex justify-content-between">
                <div v-if="item.likes">
                  <b-icon
                    icon="suit-heart-fill"
                    class="h2 bg-s hertIcon"
                    variant="danger"
                  />
                  <b-card-text class="float-right textIcon"
                  >{{ item.likes }}k
                  </b-card-text
                  >
                </div>

                <div v-if="item.comments && item.shares">
                  <div class="float-left pr-2">
                    <feather-icon size="24" icon="MessageSquareIcon"/>
                    <b-card-text class="float-right textIcon"
                    >{{ item.comments }}k
                    </b-card-text
                    >
                  </div>
                  <div class="float-right">
                    <feather-icon size="24" icon="Share2Icon"/>
                    <b-card-text class="float-right textIcon"
                    >{{ item.shares }}k
                    </b-card-text
                    >
                  </div>
                </div>


                <div v-if="item.reach">
                  <b-card-text class="float-right textIcon"
                  >Alcance: <span class="textIcon">1.25k</span></b-card-text
                  >
                </div>
                <div v-if="item.impression">
                  <b-card-text class="float-right textIcon"
                  >Impresiones:
                    <span class="textIcon">1.25k</span></b-card-text
                  >
                </div>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <b-row v-if="tab_active === 'general'" class="match-height">
      <!-- class="col-lg-4 col-md-6 col-sm-12" -->
      <b-col class="col-md-8 col-lg-7 col-sm-12 col-xl-5">
        <b-card class="bcard">
          <b-row>
            <b-col
              class="
                col-xl-3 col-lg-4
                gap-0
                col-md-5 col-sm-4
                text-center text-md-left
              "
            >
              <b-avatar
                :src="utils.getImage(profile.profile_image)"
                square
                size="137px"
              ></b-avatar>
            </b-col>

            <b-col
              class="
                col-xl-9 col-lg-8 col-md-7 col-sm-8
                pl-lg-2 pl-xl-2
                text
                mt-md-0
              "
            >
              <div>
                <b-card-title class=""
                >{{ user.first_name }} {{ user.last_name }}
                </b-card-title
                >
                <b-card-text class=""> {{ user.country }}</b-card-text>
                <b-card-text class="mt-1">
                  {{ $t("grandMaster.email") }} {{ campaign.title }}:
                  {{ user.email }}
                </b-card-text
                >
                <b-card-text class="mt-1">
                  {{ $t("grandMaster.tel") }} {{ campaign.title }}:
                  {{ user.telephone }}
                </b-card-text
                >
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col
              v-for="(item, index) in statisticsItems"
              :key="index"
              md="3"
              sm="6"
              class="mb-2 mb-md-0"
              :class="item.customClass"
            >
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="item.color">
                    <feather-icon size="24" :icon="item.icon"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 v-if="index === 2" class="font-weight-bolder mb-0">
                    {{ utils.toCurrency(item.title) }}
                  </h4>
                  <h4 v-else class="font-weight-bolder mb-0">
                    {{ utils.getFormat(item.title) }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ $t(item.subtitle) }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="col-md-4 col-lg-4 col-sm-12 col-xl-4">
        <b-card class="bcard">
          <b-card-title class="title-card"
          >{{ $t("grandMaster.codeRef") }} {{ campaign.title }}
          </b-card-title
          >
          <b-card-text class="text-black">
            <ul class="ml-1 text-justify">
              <li>{{ $t("grandMaster.subYear") }}</li>
              <li>{{ $t("grandMaster.conYear") }}</li>
              <li>{{ $t("grandMaster.discount") }}</li>
            </ul>
          </b-card-text>
          <b-card-text
            class="px-4 py-2 bg-light-primary text-left name rounded"
          >
            {{ participation.participation.code }}
          </b-card-text
          >
        </b-card>
      </b-col>
      <b-col class="col-md-12 col-lg-3 col-sm-12 col-xl-3">
        <b-card>
          <b-card-title class="title-card">{{$t('grandMaster.accessTitle')}}</b-card-title>
          <b-card-sub-title>
            <feather-icon
              icon="UserCheckIcon"
              size="18"
              class="icon-red"
            />
            {{$t('grandMaster.reward')}}
          </b-card-sub-title>
          <b-card-body class="mb-0 pl-0">
            <p><strong>{{$t("generalLeads.user")}}</strong> {{ user.email }}</p>
            <p>
              <b-input-group
                class="input-group-merge"
              >
                <strong class="mr-1">{{$t('register.password')}}:</strong>
                <b-form-input
                  id="login-password"
                  v-model="password"
                  class="form-control-merge no-form"
                  :type="passwordFieldType"
                  name="login-password"
                  disabled
                />
                <b-input-group-append is-text class="no-form">
                  <feather-icon
                    class="cursor-pointer no-form icon-blue"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </p>
            <p>
              <b-link href="https://grandmasters.academy/" target="_blank">{{$t('grandMaster.goGrandMaster')}}</b-link>
            </p>
            <b-link href="https://drive.google.com/drive/folders/1dkEL03Hc9Jn6qkP0L9AECvX_YoGQ4PY1?usp=sharing" target="_blank">
              <b-button block variant="primary">{{
                  $t("grandMaster.mainLayout")
                }}
              </b-button>
            </b-link>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col>
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title class="mb-25">
                {{ $t("grandMaster.totalRediPerMonth") }}
              </b-card-title>
              <b-card-text class="mb-0"> {{ utils.getFormat(this.statisticsItems[0].title) }}</b-card-text>
            </div>
            <feather-icon
              icon="SettingsIcon"
              size="18"
              class="text-muted cursor-pointer"
            />
          </b-card-header>

          <b-card-body class="pb-0">
            <vue-apex-charts
              ref="chart"
              type="line"
              height="240"
              :options="chartRedimidos.chartOptions"
              :series="chartRedimidos.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <div v-if="tab_active === 'details'">
      <b-card class="full-card">
        <b-table
          responsive
          show-empty
          :empty-text="this.$t('grandMaster.noPayments')"
          :items="items_table"
          class="table-header-primary"
          :fields="fields_table"
        >
          <template #head(date)="data">
            <span>{{ $t(data.label) }} </span>
          </template>
          <template #head(redeemed)="data">
            <span>{{ $t(data.label) }} </span>
          </template>
          <template #head(amount)="data">
            <span>{{ $t(data.label) }} </span>
          </template>
          <template #head(status_payment)="data">
            <span>{{ $t(data.label) }} </span>
          </template>
        </b-table>
      </b-card>
    </div>

    <div v-if="tab_active === 'payments'">
      <b-card class="card-profile objetfit">
        <div class="profile-image-wrapper profile-image-wrapper-left">
          <div class="profile-image profile-text-1 p-0">
            <b-button @click="triggerChat" variant="success"
            >{{ $t("grandMaster.requestPayment") }}
            </b-button>
          </div>
        </div>
        <div class="profile-image-wrapper profile-image-wrapper-right">
          <div class="profile-image profile-text-1 p-0">
            <h3 class="title-payments">
              {{ $t("generalLeads.registerPayments") }}
            </h3>
          </div>
        </div>

        <b-row class="mt-1">
          <b-col
            class="col-md-3 col-lg-3 col-xs-12 col-sm-12 col-xl-3 text-left"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="TrendingUpIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ utils.toCurrency(statisticsItems[2].title) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("grandMaster.totalbal") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            class="col-md-3 col-lg-3 col-xs-12 col-sm-12 col-xl-3 text-left"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="TrendingUpIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ utils.toCurrency(payments.available_amount) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("grandMaster.balanceAvaible") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            class="col-md-3 col-lg-3 col-xs-12 col-sm-12 col-xl-3 text-left"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="DollarSignIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ utils.toCurrency(payments.retired_amount) }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("grandMaster.totalWithdrawn") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            class="col-md-3 col-lg-3 col-xs-12 col-sm-12 col-xl-3 text-left"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="UserIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ statisticsItems[0].title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("grandMaster.totalUsers") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="full-card">
        <b-table
          responsive
          show-empty
          :empty-text="this.$t('grandMaster.noPayments')"
          :items="payments_table"
          class="table-header-primary"
          :fields="fields_payments"
        >
          <template #head()="data">
            <span>{{ $t(data.label) }} </span>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script lang="js">
import VueApexCharts from 'vue-apexcharts'
import {
  BEmbed,
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BButton,
  BMedia,
  BMediaBody,
  BAvatar,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardSubTitle,
  BLink,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BIcon
} from 'bootstrap-vue';
// import others from '@/services/others'
import service_leads from '@/services/leads'
import utils from '@/libs/utils'
import {$themeColors} from '@themeConfig'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'CampaignLead',
  props: {},
  mixins: [togglePasswordVisibility],
  components: {
    BCardBody,
    BCardHeader,
    VueApexCharts,
    BCardTitle,
    BCardText,
    BCard,
    BEmbed,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BTable,
    BCardFooter,
    BCardSubTitle,
    BLink,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BIcon,
  },
  mounted() {
  },
  data() {
    return {
      participation: {},
      profile: null,
      password: 'grandbrand',
      participation_approved: false,
      campaign: {
        title: '',
        brief: 'grandMaster.textBrief',
        cover: null,
        logo: null,
        participated: false
      },
      tab_active: 'brief',
      cardsSocialNetB: [
        {
          id: 1,
          avat1: require('@/assets/images/leads/brief/image 28.png'),
          avat2: require('@/assets/images/leads/brief/Avatar.png'),
          title: 'Rosa Walters',
          subtitle: 'Ejmplo de contenido',
          content: 'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit',
          img: require('@/assets/images/leads/brief/Image.png'),
          likes: '1.25',
          comments: '1.25',
          shares: '1.25'
        },
        {
          id: 2,
          avat1: require('@/assets/images/leads/brief/tiktok.png'),
          avat2: require('@/assets/images/leads/brief/Avatar.png'),
          title: 'Rosa Walters',
          subtitle: 'Ejmplo de contenido',
          img: require('@/assets/images/leads/brief/Image2.png'),
          reach: '1.25',
          impression: '1.25'
        },
        {
          id: 3,
          avat1: require('@/assets/images/leads/brief/image 28.png'),
          avat2: require('@/assets/images/leads/brief/Avatar.png'),
          title: 'Rosa Walters',
          subtitle: 'Ejmplo de contenido',
          img: require('@/assets/images/leads/brief/Image2.png'),
          reach: '1.25',
          impression: '1.25'
        },

        {
          id: 4,
          avat1: require('@/assets/images/leads/brief/twitter.png'),
          avat2: require('@/assets/images/leads/brief/Avatar.png'),
          title: 'Rosa Walters',
          subtitle: 'Ejmplo de contenido',
          content: 'Wonderful Machine· A well-written bio allows viewers to get to know a photographer beyond the work. This can make the difference when presenting to clients who are looking for the perfect fit',
          img: require('@/assets/images/leads/brief/Image3.png'),
          likes: '1.25',
          comments: '1.25',
          shares: '1.25'
        }
      ],
      cardsSocialNet: [],
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 0,
          subtitle: "grandMaster.totalRemi",
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'grandMaster.userMonth',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'grandMaster.totalEarn',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
      user: {},
      fields_table: [
        {
          key: 'date',
          label: "generalLeads.date",
          sortable: true
        },
        {
          key: 'redeemed',
          label: "generalLeads.redeemed",
          sortable: true
        },
        {
          key: 'amount',
          label: "generalLeads.amount",
          sortable: true
        },
        {
          key: 'status_payment',
          label: "generalLeads.statusPayment",
          sortable: true
        }
      ],
      items_table: [],
      payments_table: [],
      payments: {
        available_amount: 0,
        retired_amount: 0,
        data: []
      },
      fields_payments: [
        {
          key: 'payment_date',
          label: 'generalLeads.datePayment',
          sortable: true
        },
        {
          key: 'amount',
          label: 'generalLeads.amount',
          sortable: true
        },
        {
          key: 'payment_type',
          label: 'generalLeads.paymentType',
          sortable: true
        },
        {
          key: 'reference',
          label: 'generalLeads.reference',
          sortable: true
        },
      ],
      utils,
      chartRedimidos: {
        series: [
          {
            name: 'Referidos',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {show: false},
            zoom: {enabled: false},
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: {show: false},
          },
        },
      },
      link_form: 'https://brandme2.typeform.com/to/t7mur48W',
      slug: this.$route.params.slug,
    }
  },
  beforeCreate() {
    const slug_name = this.$route.params.slug;

    service_leads.getDetail(slug_name).then(response => {
      this.participation = response;
      this.participation_approved = (this.participation.participation !== null && this.participation.participation.status === 'approved_lead');
      this.campaign.title = response.name;
      this.link_form = response.link_form ? response.link_form : this.link_form
      this.campaign.logo = response.logo ? utils.getAssetUrl(response.logo) : response.logo
      this.campaign.cover = response.header ? utils.getAssetUrl(response.header) : response.header
      this.tab_active = this.participation_approved ? 'general' : 'brief';
      this.statisticsItems[0].title = response.participation && response.participation.referrals ? response.participation.referrals : 0;
      this.statisticsItems[1].title = response.participation && response.participation.month_amount ? response.participation.month_amount : 0;
      this.statisticsItems[2].title = response.participation && response.participation.total_amount ? response.participation.total_amount : 0;
      this.payments.available_amount = response.participation && response.participation.available_amount !== undefined ? response.participation.available_amount : 0;
      this.payments.retired_amount = response.participation && response.participation.retired_amount !== undefined ? response.participation.retired_amount : 0;
      const data_chart = [];
      const series_chart = [];
      if (response.participation && response.participation.summaries.length > 0) {
        this.items_table = response.participation.summaries.map(function (summary) {
          data_chart.push(summary.referrals);
          series_chart.push(summary.referrals_date);
          return {
            date: summary.referrals_date,
            redeemed: summary.referrals,
            amount: utils.toCurrency(summary.amount),
            status_payment: service_leads.getStatusPayment(summary.status)
          };
        });
        this.payments_table = response.participation.summaries.filter(function(summary) {
          if (summary.status === 'approved_payment') {
            return summary;
          }
        }).map(function(summary) {
          return {
            payment_date: summary.payment_date,
            payment_type: service_leads.getPaymentType(summary.payment_type),
            amount: utils.toCurrency(summary.amount),
            reference: summary.notes,
          };
        });
      }
      this.chartRedimidos.series[0].data = data_chart;
      this.chartRedimidos.chartOptions.xaxis.categories = series_chart;
    })
  },
  created() {
    this.profile = JSON.parse(localStorage.getItem('userData'));
    const user = utils.getUserData()

    if (user) {
      this.user = user
    } else {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    }
  },
  destroyed() {
    const body = document.querySelector('div.app-content.content');
    body.removeAttribute('style');
  },
  methods: {
    changeTab(tab_name) {
      this.tab_active = tab_name
    },
    triggerChat() {
      this.$root.$emit('open_chat_hubspot');
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },


}
</script>

<style type="text/css">
.profile-image-wrapper-left {
  justify-content: left !important;
}

.profile-image-wrapper-right {
  justify-content: right !important;
}

.profile-image-100 {
  top: -6rem !important;
}

.profile-image-100 img {
  width: 125px !important;
  height: 125px !important;
}


.profile-text {
  background-color: unset !important;
  left: 13rem;
  top: -5rem !important;
  border-radius: unset !important;
  box-shadow: none !important;
  text-align: left !important;
}

.profile-text-1 {
  background-color: unset !important;
  top: 1rem !important;
  border-radius: unset !important;
  box-shadow: none !important;
  text-align: left !important;
}

.profile-text h3 {
  font-size: 24px;
  color: white;
}

.name {
  font-size: 26px;
}

.bcard {
  min-height: 290px;
}

.navbar-lead {
  background-color: transparent !important;
  z-index: 1;
  display: flex;
  justify-content: end;
}

.card-leads-general {
  max-height: 310px;
}

.card-leads-general > img {
  object-fit: cover;
  height: 250px !important;
}

.table-header-primary table thead tr th {
  background: #3160f6 !important;
  color: white !important;
}

.title-payments {
  color: #5e5873 !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.full-card .card-body {
  padding: 0;
}

.hertIcon {
  margin-top: -4px;
}

.textIcon {
  margin-left: 5px;
}

.cardNet {
  max-height: auto;
}

.date-profile{
  height: auto;
}

@media (max-width: 731px) {
  .navbar-lead {
    margin: -2em 0 0 0;
  }

}
@media (max-width: 550px) {
  .profile-image-wrapper-left {
    justify-content: center !important;
    width: 100%;
  }
  .card-leads-general {
    max-height: 500px;
    height: 480px;
  }
  .profile-text {
    display: block;
    width: 100%;
    color: black;
    margin: 9em 0 0 0;
    left: 0;
  }
  .profile-text > h3,
  .profile-text > p {
    color: black;
    text-align: center !important;
  }
  .navbar-lead {
    margin: 0;
    justify-content: center;
  }
}
@media (max-width: 425px) {
  .text {
    margin-top: 2rem;
  }
}


@media (max-width: 382px) {
  .date-profile {
    background: white !important;
    border-radius: 5px !important;
    /* display: none; */
    z-index: 100;
    align-items: flex-end;
    top:10px !important ; 
    /* right: 100px !important; */
    left: 8rem !important;
    top: -5rem !important;
  }
  .icon{
    left: 50px !important;
  }

  .profile-header-nav {
    border-radius: 25px !important;
  }

  .profile-image-wrapper-left {
    justify-content: center !important;
  }
  .title{
    display:none
  }

  .white{
    display: none;
  }

  .icon {
    background: white;
  }
}

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.title-card {
  color: #0E131A;
  font-weight: 500;
  font-size: 1.285rem;
}

.icon-red {
  background-color: rgb(248,229,237);
  color: rgb(210,50,96);
  margin-right: 10px;
  padding: 2px;
  border-radius: 10px;
}

.no-form, .no-form > .input-group-text {
    background-color: initial !important;
    border: none !important;
    border-radius: initial !important;
}

.input-group-merge {
  align-items: center !important;
}

.icon-blue {
  color: rgb(15,36,244);
}

.video iframe, .videos object, .video embed {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.hidden{
  display: none;
}

@media (max-width: 760px) {
  .embed-responsive-16by9 {
    height: 700px !important;
  }
}

</style>
